<template>
  <div class="brick-animation">
    <div class="bricks-row">
      <div v-for="index in 12" :key="index" class="brick" />
      <div class="brick top animated"></div>
      <div v-for="index in 12" :key="index" class="brick top" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
$brick-height: 80px;
$brick-width: 160px;
$brick-translation: 60px;
$brick-margin: 20px;
$row-translation: $brick-width + $brick-margin;

$animation-time: 2s;

.brick-animation {
  position: relative;
  width: 100vw;
  height: $brick-height + $brick-translation;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;

  .bricks-row {
    display: flex;
    position: absolute;
    flex-direction: row;
    animation: $animation-time move-row infinite;

    & :last-child {
      margin-right: 0;
    }
  }

  .brick {
    background-color: $color-black;
    width: $brick-width;
    height: $brick-height;
    margin-right: $brick-margin;

    &.top {
      transform: translateY(-$brick-translation);
    }

    &.animated {
      animation: $animation-time brick-down ease-out infinite;
    }
  }

  @keyframes move-row {
    0% {
      transform: translateX(0px);
    }

    50% {
      transform: translateX(0px);
    }

    100% {
      transform: translateX(-$row-translation);
    }
  }

  @keyframes brick-down {
    0% {
      transform: translateY(-$brick-translation);
    }

    50% {
      transform: translateY(0px);
    }

    100% {
      transform: translateY(0px);
    }
  }
}
</style>
