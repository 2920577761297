<template>
  <div class="maintenance">
    <div class="container">
      <icon-base component="takbe-name" class="logo" />

      <p class="slogan title xlarge">
        Construimos<span class="bold">tu futuro</span>
      </p>

      <p class="rework">
        <span class="bold uppercase">Nuestra página web está de reformas</span
        ><br />
        para que quede todo genial, como nosotros sabemos.
      </p>

      <brick-animation></brick-animation>

      <p class="while mbottom-2 bold uppercase">
        Mientras tienes estas opciones
      </p>

      <div class="contact">
        <div class="content">
          <p class="label">Puedes llamarnos al</p>
          <a class="number" href="tel:+34649665839">649 66 58 39</a>
          <a class="number" href="tel:+34651563059">651 56 30 59</a>
        </div>

        <div class="content">
          <p class="label">Mandarnos vuestra idea a</p>
          <a class="bold">admin@takbe.es</a>
        </div>

        <div class="content">
          <p class="label">O hacernos una visita a nuestra oficina</p>
          <a
            class="bold"
            href="https://goo.gl/maps/UVDnxNNWnXhnPi8r9"
            target="_blank"
            >Camino Ancho, 29<br />Daganzo de Arriba - Madrid</a
          >
        </div>

        <p class="bye">
          Pronto tendremos nuestra web lista para enseñaros todo lo que os
          podemos ofrecer.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue";
import BrickAnimation from "@/components/molecules/BrickAnimation.vue";

export default {
  title: "TAKBE Construcciones",
  components: {
    IconBase,
    BrickAnimation,
  },
};
</script>

<style lang="scss">
.maintenance {
  height: 100vh;
  width: 100%;
  background: $color-yellow;

  .container {
    max-width: 1240px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: $color-yellow;

    margin: 0 auto;
    padding: 100px 20px;

    @media (min-width: $md-width) {
      max-width: $md-width;
    }
  }

  .logo {
    max-width: 500px;
    padding: 0 30px;
    margin-bottom: 30px;
    align-self: center;
    fill: $color-black;
  }

  .slogan {
    display: flex;
    flex-direction: column;
    font-size: 40px;
    margin-bottom: 30px;

    span {
      margin-left: 10px;
    }

    @media (min-width: $md-width) {
      flex-direction: row;
      align-self: center;
    }
  }

  .rework {
    font-size: 20px;
    margin-bottom: 10px;

    @media (min-width: $md-width) {
      margin: 0 auto;
      margin-bottom: 10px;
      text-align: center;
    }

    .bold {
      font-size: 28px;
    }
  }

  .while {
    align-self: center;
    font-size: 28px;
  }

  .contact {
    font-size: 20px;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 440px;
    align-self: center;
  }

  .brick-animation {
    margin-bottom: 40px;
    align-self: center;
  }

  .contact {
    .content {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-bottom: 20px;

      .label {
        margin-bottom: 4px;
      }

      .bold {
        margin: 0 auto;
        font-size: 22px;
        text-align: center;
      }

      .number {
        font-size: 28px;
        font-weight: bold;
        margin: 0 auto;
      }
    }

    .bye {
      margin-top: 20px;
      text-align: center;
    }
  }
}
</style>
