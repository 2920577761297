<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 664.35 173.48">
    <g id="Capa_1" data-name="Capa 1">
      <path
        d="M176.24,202v83.4H128.39V202H91.19V166.9h122.4V202Z"
        transform="translate(-91.19 -166.9)"
      />
      <path
        d="M247.49,285.4H198.6l47.09-118.5h60.45l43.8,118.5h-49.8l-7.5-22.5h-37Zm29.4-70.8-1.95-7.5-2.25,7.8-7.35,19.8h18.3Z"
        transform="translate(-91.19 -166.9)"
      />
      <path
        d="M435.74,285.4l-28.65-38.25-4.5,4.8V285.4H355.64V166.9h46.95v35.55l34.35-35.55h55.35l-50.85,52.95L493,285.4Z"
        transform="translate(-91.19 -166.9)"
      />
      <path
        d="M592.34,285.4H499.79V166.9H592c16.35,0,25.8,4,31.2,9.6,4.8,4.8,7.35,11.1,7.35,19.65,0,7.5-1.8,14.4-5.55,19.35-3,4.05-9.45,6.9-15.3,7.8a30.54,30.54,0,0,1,19.5,11.1c3.15,4,5,10.35,5,17.7,0,7.65-2.4,16.5-6.9,21.6C620.39,281.65,608.69,285.4,592.34,285.4Zm-12.75-86.55c-1.5-1.5-4.05-2.1-7.8-2.1H545.84V212.2h26.1c3.75,0,6.6-.75,8.1-2.85a7.61,7.61,0,0,0,1.35-4.8A8.12,8.12,0,0,0,579.59,198.85Zm5.1,49.35c0-2.7-.45-4.95-1.65-6.45-1.5-1.65-3.9-2.4-6.9-2.4h-30.3v16.5h30c3.15,0,5.7-.6,7.2-2.25A8,8,0,0,0,584.69,248.2Z"
        transform="translate(-91.19 -166.9)"
      />
      <path
        d="M643,166.9v33.45h112.5V166.9Zm0,42.3V243h112.5V209.2Zm0,43.2v33h112.5v-33Z"
        transform="translate(-91.19 -166.9)"
      />
      <text class="cls-1" transform="translate(121.56 167.27)">
        CONSTRUCCIONES S.L.
      </text>
    </g>
  </svg>
</template>

<style scoped>
.cls-1 {
  font-size: 29px;
  font-family: Helvetica, Arial;
  letter-spacing: 0.2em;
}
</style>
