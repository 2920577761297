<template>
  <component :is="component" />
</template>

<script>
import TakbeName from "./components/TakbeName";

export default {
  components: {
    TakbeName,
  },
  props: {
    component: {
      type: String,
      default: "takbe-name",
    },
  },
};
</script>
